// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-wp-blog-js": () => import("./../../../src/layouts/wp-blog.js" /* webpackChunkName: "component---src-layouts-wp-blog-js" */),
  "component---src-layouts-wp-page-js": () => import("./../../../src/layouts/wp-page.js" /* webpackChunkName: "component---src-layouts-wp-page-js" */),
  "component---src-layouts-wp-post-js": () => import("./../../../src/layouts/wp-post.js" /* webpackChunkName: "component---src-layouts-wp-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-come-funziona-js": () => import("./../../../src/pages/come-funziona.js" /* webpackChunkName: "component---src-pages-come-funziona-js" */),
  "component---src-pages-conosciamoci-meglio-js": () => import("./../../../src/pages/conosciamoci-meglio.js" /* webpackChunkName: "component---src-pages-conosciamoci-meglio-js" */),
  "component---src-pages-contattaci-js": () => import("./../../../src/pages/contattaci.js" /* webpackChunkName: "component---src-pages-contattaci-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lavora-con-noi-js": () => import("./../../../src/pages/lavora-con-noi.js" /* webpackChunkName: "component---src-pages-lavora-con-noi-js" */),
  "component---src-pages-manifesto-js": () => import("./../../../src/pages/manifesto.js" /* webpackChunkName: "component---src-pages-manifesto-js" */),
  "component---src-pages-thank-you-contattaci-js": () => import("./../../../src/pages/thank-you-contattaci.js" /* webpackChunkName: "component---src-pages-thank-you-contattaci-js" */),
  "component---src-pages-thank-you-lavora-con-noi-js": () => import("./../../../src/pages/thank-you-lavora-con-noi.js" /* webpackChunkName: "component---src-pages-thank-you-lavora-con-noi-js" */),
  "component---src-pages-vantaggi-js": () => import("./../../../src/pages/vantaggi.js" /* webpackChunkName: "component---src-pages-vantaggi-js" */)
}

